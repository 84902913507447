import cs from 'classnames';
import Image from 'next/image';
import React from 'react';

import Link from '../../components/link/Link';
import { Routes, route } from '../../utilities/routes';

export type Props = {
  className?: string;
  padded?: boolean;
  withLogo?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const Container = ({
  className,
  padded = true,
  children,
  withLogo = false,
  ...props
}: Props) => {
  return (
    <div
      className={cs('mx-auto w-full', className, {
        'px-4 pt-4 pb-24 md:px-8 md:pt-6': padded,
      })}
      {...props}
    >
      {withLogo ? (
        <div className="w-100 flex justify-center pt-4 pb-6 md:pt-10 md:pb-8">
          <Link href={route(Routes.home)}>
            <Image
              src="/packfleet-brand.svg"
              alt="Packfleet logo"
              height={31}
              width={116}
            />
          </Link>
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default Container;
