import React from 'react';

import { Routes, route } from '../../utilities/routes';
import LinkButton from '../button/LinkButton';
import Container from '../container/Container';
import Heading from '../heading/Heading';
import Link from '../link/Link';

export const ErrorPage = ({ statusCode }: { statusCode?: number }) => {
  return (
    <div className="w-100 flex h-screen flex-1 flex-col bg-cover bg-no-repeat">
      <Container withLogo>
        <Heading
          level={1}
          headingStyle="hero"
          className="mb-4 mt-8 text-center"
        >
          Oh no
        </Heading>
        <div className="flex flex-col justify-center">
          <p className="text-md mx-auto max-w-md text-center text-secondary">
            {statusCode === 404 ? (
              <>Sorry, we can&apos;t find the page you&apos;re looking for.</>
            ) : (
              <>
                Sorry, something went wrong. Our team has been notified, but if
                this continues, let us know at{' '}
                <Link href="mailto:hello@packfleet.com">
                  hello@packfleet.com
                </Link>{' '}
                and we will gladly help. In the meantime, try refreshing the
                page.
              </>
            )}
          </p>
          <div className="mx-auto pt-16">
            <LinkButton href={route(Routes.appDashboard)}>
              Back to dashboard
            </LinkButton>
          </div>
        </div>
      </Container>
    </div>
  );
};
